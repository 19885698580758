import React from "react"
export default function Sprites() {
    return (
        <svg style={{display: `none`, width: `0`, height: `0`}}>
            <symbol id="facebook" viewBox="0 0 10.175 20.12">
                <title>facebook</title>
                <path d="M3.008,20.12v-9.177H0V7.365h3.008V4.729C3.008,1.668,4.83,0,7.487,0c1.275,0,2.369,0.099,2.688,0.141v3.2
            H8.332c-1.446,0-1.726,0.707-1.726,1.741v2.283h3.449l-0.451,3.578H6.606v9.177"/>
            </symbol>
            <symbol id="instagram" viewBox="0 0 20.998 20.998">
                <title>instagram</title>>
                <g>
                    <path d="M10.499,1.893c2.804,0,3.136,0.01,4.242,0.061c1.023,0.047,1.58,0.218,1.95,0.361
                        c0.489,0.191,0.839,0.418,1.207,0.786c0.367,0.367,0.595,0.717,0.785,1.207c0.144,0.37,0.314,0.926,0.361,1.949
                        c0.051,1.107,0.062,1.439,0.062,4.242c0,2.804-0.011,3.136-0.062,4.243c-0.047,1.023-0.218,1.579-0.361,1.949
                        c-0.19,0.49-0.418,0.84-0.785,1.207c-0.368,0.367-0.718,0.596-1.207,0.785c-0.37,0.145-0.927,0.315-1.95,0.362
                        c-1.106,0.05-1.438,0.061-4.242,0.061s-3.136-0.011-4.242-0.061c-1.024-0.047-1.58-0.218-1.95-0.362
                        c-0.49-0.189-0.84-0.418-1.207-0.785s-0.595-0.717-0.785-1.207C2.171,16.321,2,15.766,1.953,14.742
                        c-0.051-1.107-0.062-1.439-0.062-4.243c0-2.803,0.011-3.135,0.062-4.242C2,5.233,2.171,4.678,2.314,4.308
                        c0.19-0.49,0.418-0.84,0.785-1.207c0.367-0.368,0.717-0.595,1.207-0.786C4.677,2.171,5.232,2,6.257,1.953
                        C7.363,1.902,7.695,1.893,10.499,1.893 M10.499,0C7.647,0,7.29,0.013,6.17,0.063C5.053,0.114,4.29,0.292,3.622,0.552
                        c-0.69,0.269-1.276,0.627-1.86,1.211C1.179,2.347,0.819,2.932,0.551,3.622C0.292,4.29,0.114,5.053,0.063,6.171
                        C0.012,7.291,0,7.648,0,10.499c0,2.852,0.012,3.209,0.063,4.329c0.051,1.117,0.229,1.881,0.487,2.549
                        c0.269,0.69,0.628,1.275,1.211,1.859c0.584,0.584,1.17,0.942,1.86,1.211c0.668,0.26,1.431,0.438,2.548,0.488
                        c1.12,0.051,1.478,0.062,4.329,0.062s3.209-0.012,4.328-0.062c1.118-0.051,1.881-0.229,2.549-0.488
                        c0.69-0.269,1.276-0.627,1.859-1.211c0.584-0.584,0.943-1.169,1.211-1.859c0.26-0.668,0.438-1.432,0.488-2.549
                        c0.051-1.12,0.063-1.478,0.063-4.329c0-2.851-0.013-3.208-0.063-4.328c-0.051-1.118-0.229-1.881-0.488-2.549
                        c-0.268-0.69-0.627-1.275-1.211-1.859c-0.583-0.584-1.169-0.942-1.859-1.211c-0.668-0.26-1.431-0.438-2.549-0.488
                        C13.708,0.013,13.351,0,10.499,0L10.499,0z"/>
                    <path d="M10.499,5.108c-2.978,0-5.392,2.413-5.392,5.391s2.414,5.392,5.392,5.392s5.392-2.414,5.392-5.392
                        S13.477,5.108,10.499,5.108z M10.499,13.999c-1.933,0-3.5-1.566-3.5-3.5C6.999,8.566,8.566,7,10.499,7s3.5,1.566,3.5,3.499
                        C13.999,12.433,12.432,13.999,10.499,13.999z"/>
                    <circle cx="16.104" cy="4.896" r="1.26"/>
                </g>
            </symbol>
            <symbol id="linkedin" viewBox="0 0 50 50">
            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g fill="#fff" id="LinkedIn">
                <path d="M25,50 C38.8071194,50 50,38.8071194 50,25 C50,11.1928806 38.8071194,0 25,0 C11.1928806,0 0,11.1928806 0,25 C0,38.8071194 11.1928806,50 25,50 Z M25,47 C37.1502651,47 47,37.1502651 47,25 C47,12.8497349 37.1502651,3 25,3 C12.8497349,3 3,12.8497349 3,25 C3,37.1502651 12.8497349,47 25,47 Z M14,20.1180479 L14,34.6581834 L18.7100851,34.6581834 L18.7100851,20.1180479 L14,20.1180479 Z M16.6646962,13 C15.0534058,13 14,14.0858611 14,15.5115122 C14,16.9076331 15.0222711,18.0247614 16.6035556,18.0247614 L16.6336556,18.0247614 C18.2759867,18.0247614 19.2988222,16.9076331 19.2988222,15.5115122 C19.2682519,14.0858611 18.2759867,13 16.6646962,13 Z M30.5769213,20.1180479 C28.076176,20.1180479 26.9565501,21.5293199 26.3314108,22.5193527 L26.3314108,20.4598644 L21.6207614,20.4598644 C21.6828427,21.8242356 21.6207614,35 21.6207614,35 L26.3314108,35 L26.3314108,26.8795887 C26.3314108,26.445032 26.3619812,26.0115368 26.4865199,25.7004084 C26.826932,24.83226 27.6020069,23.9334233 28.9032674,23.9334233 C30.6083381,23.9334233 31.2899149,25.2667202 31.2899149,27.2206333 L31.2899149,34.999614 L35.9998119,34.999614 L36,26.6627446 C36,22.1966439 33.6763743,20.1180479 30.5769213,20.1180479 Z M30.5769213,20.1180479" id="Oval-1"/>
                </g>
            </g>
        </symbol>
        <symbol id="down-arrow" viewBox="0 0 18 28">
            <path d="M7.826 0h2.348v23.645l6.26-6.756L18 18.448l-8.87 9.094L0 18.448l1.565-1.559 6.261 6.496V0z"></path>
        </symbol>
        </svg>
    );
}